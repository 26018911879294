<template>
  <div class="contain" ref="contains">
    <div class="myOrder" ref="refHeight">
      {{ orderDetail.orderStatus || "-" }}
    </div>
    <!-- 下拉刷新 -->
    <PullDownRefresh @refresh="refresh">
      <div class="main">
        <div class="whiteBlock goods">
          <div class="top">无人车 {{ orderDetail.vinId || "-" }}</div>
          <div class="list">
            <div
              class="item"
              v-for="item in orderDetail.skuList"
              :key="item.skuCode"
            >
              <div class="imgBox">
                <img class="img" :src="item.skuImage" />
              </div>
              <div class="name">
                <span>{{ item.skuName }}</span>
                <span class="num">x{{ item.skuCount }}</span>
              </div>

              <div class="price">
                <span class="sub">￥</span>
                <span class="num">{{ item.formatSkuPrice[0] }}</span>
                <span class="point">{{ item.formatSkuPrice[1] }}</span>
              </div>
            </div>
          </div>

          <div class="list2">
            <span class="list2Key">订单金额</span>
            <div class="list2Value">
              <span class="sub">￥</span>
              <span class="num">{{
                orderDetail.formatOriPrice && orderDetail.formatOriPrice[0]
              }}</span>
              <span class="point">{{
                orderDetail.formatOriPrice && orderDetail.formatOriPrice[1]
              }}</span>
            </div>
          </div>
          <div class="list2" v-if="orderDetail.deratePrice">
            <span class="list2Key">已优惠</span>
            <div class="red">
              <span class="sub">-￥</span>
              <span class="num">{{
                orderDetail.formatOriPrice && orderDetail.formatDeratePrice[0]
              }}</span>
              <span class="point">{{
                orderDetail.formatOriPrice && orderDetail.formatDeratePrice[1]
              }}</span>
            </div>
          </div>
          <div class="list3">
            <span>实付金额</span>
            <span class="sub">￥</span>
            <span class="num">{{
              orderDetail.formatOriPrice && orderDetail.formatTotalPrice[0]
            }}</span>
            <span class="point">{{
              orderDetail.formatOriPrice && orderDetail.formatTotalPrice[1]
            }}</span>
          </div>
        </div>

        <div class="whiteBlock info">
          <div class="infoTit">订单信息</div>
          <div class="item">
            <span class="tit">订单号</span>
            <span>{{ orderDetail.orderCode }}</span>
          </div>
          <div class="item">
            <span class="tit">下单时间</span>
            <span>{{ orderDetail.createOrderTime }}</span>
          </div>
          <template v-if="orderDetail.refundOrderStatus !== 0">
            <div class="item">
              <span class="tit">退款状态</span>
              <span>{{ orderDetail.refundOrderStatusValue }}</span>
            </div>
            <div
              class="item"
              v-if="
                orderDetail.finalRefundPrice &&
                orderDetail.refundOrderStatus === 2
              "
            >
              <span class="tit">退款金额</span>
              <span>{{ orderDetail.finalRefundPrice }}</span>
            </div>
            <div class="item" v-else>
              <span class="tit">申请退款金额</span>
              <span>{{ orderDetail.totalPrice }}</span>
            </div>
            <div class="item" v-if="orderDetail.refundReason">
              <span class="tit"
                >{{
                  orderDetail.refundOrderStatus === 5 ||
                  orderDetail.refundOrderStatus === 7
                    ? "驳回"
                    : "退款"
                }}原因</span
              >
              <span>{{ orderDetail.refundReason }}</span>
            </div>
          </template>
        </div>
      </div>
    </PullDownRefresh>

    <!--底部浮动box-->
    <!-- <div class="bottom">
      <div class="btn" v-if="orderDetail.isHaveRefund">退款</div>
      <div
        class="btn"
        v-if="
          (orderDetail.refundOrderStatus === 1 ||
            orderDetail.refundOrderStatus === 2 ||
            orderDetail.refundOrderStatus === 6 ||
            orderDetail.refundOrderStatus === 7) &&
          orderDetail.totalPrice !== 0
        "
      >
        退款记录
      </div>
      <div
        class="btn btn2"
        v-if="
          orderDetail.commented === false &&
          userInfo.avatarUrl &&
          userInfo.nickName
        "
      >
        评价
      </div>
      <div
        class="btn btn2"
        v-if="
          orderDetail.commented === false &&
          (!userInfo.avatarUrl || !userInfo.nickName)
        "
        catchtap="getUserInfo"
      >
        评价
      </div>
    </div> -->
  </div>
</template>

<script>
import request from "@/utils/request";
import PullDownRefresh from "@/components/PullDownRefresh";

export default {
  name: "OrderDetails",
  components: {
    PullDownRefresh,
  },
  data() {
    return {
      orderDetail: {},
      orderCode: "",
      timer: null,
    };
  },
  created: function () {
    console.log(this.$route.query);
    this.orderCode = this.$route.query.orderCode;
    this.getOrderDetail();
  },

  mounted: function () {
    this.$refs.contains.addEventListener("scroll", this.handleScroll);
    this.timer = setInterval(() => {
      this.getOrderDetail();
    }, 60000);
  },
  destroyed() {
    // this.$refs.contains.addEventListener("scroll", this.handleScroll);
    clearInterval(this.timer);
    this.utils.setTitle("");
  },

  methods: {
    getOrderDetail(done) {
      let data = {
        orderCode: this.orderCode,
      };
      request({
        url: "/web/order/orderDetail",
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        console.log(res);
        done && done();
        res.formatOriPrice = this.$splitPrice(res.oriPrice); // 订单金额
        res.formatDeratePrice = this.$splitPrice(res.deratePrice); // 优惠金额
        res.formatTotalPrice = this.$splitPrice(res.totalPrice); // 实付金额
        res.formatFinalRefundPrice = this.$splitPrice(res.finalRefundPrice); // 退款金额
        res.skuList &&
          res.skuList.map((item) => {
            item.formatSkuPrice = this.$splitPrice(item.skuPrice);
          });
        this.orderDetail = res;
      });
    },
    refresh(done) {
      this.getOrderDetail(done);
    },
    handleScroll() {
      let scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
      let oneHeight = this.$refs.refHeight.offsetHeight;
      if (-oneHeight >= scrollTop) {
        this.utils.setTitle(this.orderDetail.orderStatus);
      } else {
        this.utils.setTitle("");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 100%;
  float: left;
  overflow: scroll;
  position: relative;
  padding-top: 1.16rem;
  @include background_color("bg");

  .myOrder {
    width: 100%;
    height: 1.16rem;
    line-height: 1.16rem;
    font-size: 0.48rem;
    text-indent: 0.48rem;
    margin: 0 auto;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @include background_color("title_bg");
    @include font_color("title_color");
  }
  .main {
    padding: 0 0.3rem;
    width: 100%;
    float: left;
    & > .goods {
      display: flex;
      flex-direction: column;

      .top {
        font-size: 0.32rem;
        font-weight: bold;
        padding: 0.24rem 0;
        margin-bottom: 0.14rem;
        @include font_color("card_header_color");
      }
      .list {
        .item {
          display: flex;
          margin-top: 0.12rem;
          padding-bottom: 0.4rem;

          &:first-child {
            margin-top: 0;
          }

          .imgBox {
            width: 1.12rem;
            height: 1.12rem;
            border-radius: 0.08rem;
            margin-right: 0.1rem;
            overflow: hidden;
            .img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            width: calc(100% - 1.12rem);
            height: 1.12rem;
            font-size: 0.28rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span {
              display: inline-block;
              line-height: 0.36rem;
              font-size: 0.3rem;
              font-weight: 600;
              overflow: hidden;
              @include font_color("card_price_color");
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            span.text {
              width: 120%;
            }

            span.num {
              font-size: 0.26rem;
              color: #aeb5ca;
            }
          }
          .price {
            height: 1.12rem;
            flex: 1;
            font-size: 0.3rem;
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            @include font_color("card_price_color");

            span.sub {
              font-size: 0.22rem;
              font-weight: normal;
            }
            .num {
              font-size: 0.36rem;
              font-weight: bold;
              line-height: 0.39rem;
            }
            .point {
              font-size: 0.24rem;
              font-weight: bold;
            }
          }
        }

        .item {
          margin-top: 0.12rem;
          font-size: 0.28rem;
          color: #222;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:first-child {
            margin-top: 0;
          }

          .sub {
            font-size: 0.22rem;
          }
          .num {
            font-size: 0.3rem;
            font-weight: bold;
          }
          .red {
            color: #e71f19;
          }
        }
      }
      .list2 {
        height: 0.56rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font_color("card_price_color");
        .list2Key {
          font-size: 0.28rem;
          margin-top: 0;
        }
        .list2Value {
          height: 0.56rem;
          line-height: 0.2rem;
        }
        .sub {
          font-size: 0.22rem;
          line-height: 0.56rem;
        }
        .num {
          font-size: 0.36rem;
          font-weight: bold;
        }
        .point {
          font-size: 0.24rem;
          font-weight: bold;
        }
        .red {
          color: #e71f19;
          height: 0.56rem;
          line-height: 0.2rem;
        }
      }
      .list3 {
        height: 1.21rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top-width: 0.01rem;
        border-top-style: solid;
        margin-top: 0.19rem;
        @include border_color("detail_border_color");
        @include font_color("card_price_color");
        > :first-child {
          font-size: 0.32rem;
          margin-top: 0;
          margin-right: 0.2rem;
        }

        .sub {
          font-size: 0.28rem;
          font-weight: bold;
        }
        .num {
          font-size: 0.44rem;
          font-weight: bold;
        }
        .point {
          font-size: 0.32rem;
          font-weight: bold;
        }
        .red {
          color: #e71f19;
        }
      }
    }

    & > .info {
      padding: 0.3rem;
      padding-top: 0;
      .infoTit {
        font-size: 0.28rem;
        padding: 0.28rem 0 0.24rem;
        @include font_color("card_header_color");
      }
      .item {
        min-height: 0.36rem;
        margin-top: 0.16rem;
        font-size: 0.28rem;
        color: #222222;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          margin-top: 0;
        }

        .tit {
          color: #aeb5ca;

          & + span {
            max-width: 4.7rem;
            text-align: right;
            @include font_color("card_info_color");
          }
        }
      }
    }
  }
  .whiteBlock {
    border-radius: 0.12rem;
    margin-top: 0.2rem;
    padding: 0 0.3rem;
    @include background_color("card_bg");
  }
  .bottom {
    width: calc(100% - 0.72rem);
    height: 1.24rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.36rem;
    background: #fff;
    border-top: 0.01rem solid #e0e0e0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;

    .btn {
      padding: 0.12rem 0.4rem;
      border: 0.01rem solid #cccccc;
      border-radius: 0.32rem;
      font-size: 0.26rem;
      color: #666;
      font-weight: bold;
      margin-left: 0.3rem;

      &:first-child {
        margin-left: 0;
      }
    }
    .btn2 {
      background: #f6c24a;
      color: #222;
      border: none;
    }
  }
}
</style>
